import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { composeClasses, jsonId } from 'utils'
import SEO from '../components/seo'
import MemberSVG from 'components/MemberSVG'
import shareIcon from '../images/shareIcon.svg'
import ShareLarge from 'components/shareLarge'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import Accordion from 'components/accordion'
import { memberGenderImage } from '../styles/accordion.module.css'
import HelmetExport from 'react-helmet'
import {
  wrapper,
  blogBackground,
  verticalContainer,
  breadCrumbsSection,
  verticalContainerInner,
  headingContainer,
  teamContainer,
  memberContainerInner,
  memberInfo,
  memberName,
  memberJob,
  cityCountry,
  linkContainer,
  profileLink,
  shareContainer,
  modalContainer,
  modalContainerInner
} from '../styles/team.module.css'

export const query = graphql`
  query($first: Int) {
    wordPress {
      teamMembers(first: $first) {
        edges {
          node {
            id
            status
            slug
            membersFieldGroup {
              memberFirstName
              memberSurname
              memberJobTitle
              memberCity
              memberCountry
              memberGender
              memberPosition
              memberQuote
              memberJobGroup
              isIntern
              isAlumni
            }
          }
        }
      }
      pages(where: { name: "Member" }) {
        edges {
          node {
            headerSection {
              heroHeading
              heroText
            }
            title
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const normalizeMemberList = data => {
  let orderedList = []
  if (data.wordPress.teamMembers.edges) {
    orderedList = data.wordPress.teamMembers.edges
      .reduce((acc, item) => {
        const member = item.node.membersFieldGroup
        if (item.node.status === 'publish' && member.isIntern !== true && member.isAlumni !== true) {
          if (!acc[member.memberJobGroup]) {
            acc[member.memberJobGroup] = []
          }
          acc[member.memberJobGroup].push({
            ...member,
            slug: item.node.slug
          })
        }

        return acc
      }, [])
  }

  return orderedList
}

let cSuiteGroup = []
let managementGroup = []
let leadershipGroup = []

const TeamPage = ({ data, pageContext }) => {
  const [memberUrl, setMemberUrl] = useState('')
  const headerSection = data.wordPress.pages.edges[0].node.headerSection
  const memberList = normalizeMemberList(data)
  const pageTitle = data.wordPress.pages.edges[0].node.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const contextSlug = pageContext.slug
  const {
    breadcrumb: { crumbs }
  } = pageContext

  Object.keys(memberList).map((group) => {
    if (group === 'C-Suite') {
      cSuiteGroup = memberList[group].sort((first, second) => first.memberPosition - second.memberPosition)
    } else if (group === 'Management') {
      managementGroup = memberList[group]
    } else if (group === 'Leadership') {
      leadershipGroup = memberList[group]
    }
  })

  const openModal = (slug) => {
    setMemberUrl(`${window.location.href}/${slug}`)
    const modal = document.getElementById('shareModal')

    modal.style.display = 'block'

    window.onclick = function (event) {
      if (event.target === modal) {
        modal.style.display = 'none'
      }
    }
  }

  return (
    <>
      <HelmetExport>
        {jsonId({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          'itemListElement': [{
            '@type': 'ListItem',
            'item': siteUrl,
            'name': 'Home',
            'position': 1
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/${contextSlug}`,
            'name': `${pageTitle}`,
            'position': 2
          }]
        })}
      </HelmetExport>
      <SEO
        description='We are a global company, with members from all over the world. We believe that the strength of our team is what makes us so effective.'
        title='Crew'
      />
      <div
        className={composeClasses(wrapper, blogBackground)}
      >
        <div
          className={composeClasses(
            verticalContainer,
            breadCrumbsSection
          )}
        >
          <div className={verticalContainerInner}>
            <Breadcrumb
              crumbLabel='The Team'
              crumbs={crumbs}
              crumbSeparator=' > '
            />
          </div>
          <div className={headingContainer}>
            <h1>{headerSection.heroHeading}</h1>
            <p>{headerSection.heroText}</p>
          </div>
        </div>
        <div
          className={teamContainer}
          id='C-Suite'>
          {cSuiteGroup.length > 0 && (
            <Accordion
              content={
                cSuiteGroup.map((member, key) => {
                  const gender = member.memberGender
                  return (
                    <div
                      className={memberContainerInner}
                      key={key.toString()}>
                      <div>
                        <div className={memberGenderImage}>
                          <MemberSVG gender={gender} />
                        </div>
                        <div className={memberInfo}>
                          <span
                            className={memberName}
                          >
                            {`${member.memberFirstName} ${member.memberSurname}`}
                          </span>
                          <span className={memberJob}>
                            {member.memberJobTitle}
                          </span>
                          <span
                            className={cityCountry}
                          >
                            {`${member.memberCity} - ${member.memberCountry}`}
                          </span>
                          <div className={linkContainer}>
                            <Link to={`/member/${member.slug}`}>
                              <span className={profileLink}>
                                View Profile
                              </span>
                            </Link>
                            <button
                              className={shareContainer}
                              id='shareButton'
                              onClick={() => openModal(member.slug)}
                            >
                              <img src={shareIcon} />
                              <p>Share Profile</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              contentTitle={cSuiteGroup[0].memberJobGroup}
              imageId={0}
              isExpanded
              numbers=''
              teamPage
            />
          )}
        </div>
        <div
          className={teamContainer}
          id='Management'>
          {managementGroup.length > 0 && (
            <Accordion
              content={
                managementGroup.map((member, key) => {
                  const gender = member.memberGender
                  return (
                    <div
                      className={memberContainerInner}
                      key={key}>
                      <div>
                        <MemberSVG gender={gender} />
                        <div className={memberInfo}>
                          <span
                            className={memberName}
                          >{`${member.memberFirstName} ${member.memberSurname}`}</span>
                          <span className={memberJob}>
                            {member.memberJobTitle}
                          </span>
                          <span
                            className={cityCountry}
                          >{`${member.memberCity} - ${member.memberCountry}`}</span>
                          <div className={linkContainer}>
                            <Link to={`/member/${member.slug}`}>
                              <span className={profileLink}>
                                View Profile
                              </span>
                            </Link>
                            <button
                              className={shareContainer}
                              id='shareButton'
                              onClick={() => openModal(member.slug)}
                            >
                              <img src={shareIcon} />
                              <p>Share Profile</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              contentTitle={managementGroup[0].memberJobGroup}
              imageId={0}
              numbers=''
              teamPage
            />
          )}
        </div>
        <div
          className={teamContainer}
          id='Leadership'>
          {leadershipGroup.length > 0 && (
            <Accordion
              content={
                leadershipGroup.map((member, key) => {
                  const gender = member.memberGender
                  return (
                    <div
                      className={memberContainerInner}
                      key={key}>
                      <div>
                        <MemberSVG gender={gender} />
                        <div className={memberInfo}>
                          <span
                            className={memberName}
                          >{`${member.memberFirstName} ${member.memberSurname}`}</span>
                          <span className={memberJob}>
                            {member.memberJobTitle}
                          </span>
                          <span
                            className={cityCountry}
                          >{`${member.memberCity} - ${member.memberCountry}`}</span>
                          <div className={linkContainer}>
                            <Link to={`/member/${member.slug}`}>
                              <span className={profileLink}>
                                View Profile
                              </span>
                            </Link>
                            <button
                              className={shareContainer}
                              id='shareButton'
                              onClick={() => openModal(member.slug)}
                            >
                              <img src={shareIcon} />
                              <p>Share Profile</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              contentTitle={leadershipGroup[0].memberJobGroup}
              imageId={0}
              numbers=''
              teamPage
            />
          )}
        </div>
        <div
          className={modalContainer}
          id='shareModal'>
          <div className={modalContainerInner}>
            <ShareLarge
              pageName='profile'
              url={memberUrl} />
          </div>
        </div>
      </div>
    </>
  )
}

TeamPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default TeamPage
